import React, { useState, useEffect } from 'react';
import {
    Row, Col,
    Card, CardBody,
    CardTitle, Table,
    Tooltip,
    Button
} from 'reactstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { getTendinopathiesListAPI } from './../../../api/network/Api';
import PageTitle from '../../../Layout/AppMain/PageTitle';
import axios from "axios";
import { toast, Bounce } from 'react-toastify';
import Pagination from '../../../api/helperComponents/Pagination';
import { useHistory, useParams } from 'react-router-dom';
import userUtil from '../../../utils/UserUtils';
import Loader from '../../../api/helperComponents/Loader';
import BreadCrumb from '../../../api/helperComponents/BreadCrumb';
import { dateTimeFormate } from './../../../api/helperComponents/dateTimeFormat';
import { isToday } from '../../../utils/isToday';
const DiagnosisList = (props) => {
    const history = useHistory()
    const cancelTokenSource = axios.CancelToken.source();
    const params = useParams()
    const [diagnosisList, setDiagnosisList] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isLoader, setIsLoader] = useState(false);
    const [centerId, setCenterId] = useState(JSON.parse(localStorage.getItem('userDetail'))?.center_id);
    const [permissions, setPermissions] = useState(JSON.parse(localStorage.getItem('userPermissions')));
    const [patientNameID, setPatientNameID] = useState(localStorage.getItem('patientNameID'));
    const [role, setRole] = useState(JSON.parse(localStorage.getItem('userDetail'))?.role?.name);
    const [diagnosisQuestions, setDiagnosisQuestions] = useState([
        {
            left_shoulder: [
                { SUBACROMIAL_BURSITIS: { key: 'SUBACROMIAL_BURSITIS', value: 1, name: 'Subacromial Bursitis' } },
                { ADHESIVE_CAPSULITIS: { key: 'ADHESIVE_CAPSULITIS', value: 2, name: 'Adhesive Capsulitis' } },
                { SUPRASPINATUS_TENDINITIS: { key: 'SUPRASPINATUS_TENDINITIS', value: 3, name: 'Supraspinatus Tendinitis' } },
                { BICIPITAL_TENDINITIS: { key: 'BICIPITAL_TENDINITIS', value: 4, name: 'Bicipital Tendinitis' } },
                { RADICULAR: { key: 'RADICULAR', value: 5, name: 'Radicular' } },
                { NONE: { key: 'NONE', value: 6, name: 'None' } }
            ],
        },
        {
            right_shoulder: [
                { SUBACROMIAL_BURSITIS: { key: 'SUBACROMIAL_BURSITIS', value: 1, name: 'Subacromial Bursitis' } },
                { ADHESIVE_CAPSULITIS: { key: 'ADHESIVE_CAPSULITIS', value: 2, name: 'Adhesive Capsulitis' } },
                { SUPRASPINATUS_TENDINITIS: { key: 'SUPRASPINATUS_TENDINITIS', value: 3, name: 'Supraspinatus Tendinitis' } },
                { BICIPITAL_TENDINITIS: { key: 'BICIPITAL_TENDINITIS', value: 4, name: 'Bicipital Tendinitis' } },
                { RADICULAR: { key: 'RADICULAR', value: 5, name: 'Radicular' } },
                { NONE: { key: 'NONE', value: 6, name: 'None' } }
            ],
        },

        {
            left_knee: [
                { MENISCUS_TEAR_MEDIAL: { key: 'MENISCUS_TEAR_MEDIAL', value: 1, name: 'Meniscus Tear Medial' } },
                { MENISCUS_TEAR_LATERAL: { key: 'MENISCUS_TEAR_LATERAL', value: 2, name: 'Meniscus Tear Lateral' } },
                { CRUCIATE_INJURY_ANTERIOR: { key: 'CRUCIATE_INJURY_ANTERIOR', value: 3, name: 'Cruciate Injury Anterior' } },
                { CRUCIATE_INJURY_POSTERIOR: { key: 'CRUCIATE_INJURY_POSTERIOR', value: 4, name: 'Cruciate Injury Posterior' } },
                { BURSITIS_SUPRAPATELLAR: { key: 'BURSITIS_SUPRAPATELLAR', value: 5, name: 'Bursitis Suprapatellar' } },
                { BURSITIS_PREPATELLAR: { key: 'BURSITIS_PREPATELLAR', value: 6, name: 'Bursitis Prepatellar' } },
                { BURSITIS_INFRAPATELLAR: { key: 'BURSITIS_INFRAPATELLAR', value: 7, name: 'Bursitis Infrapatellar' } },
                { RADICULAR: { key: 'RADICULAR', value: 8, name: 'Radicular' } },
                { NONE: { key: 'NONE', value: 9, name: 'None' } }
            ],
        },
        {
            right_knee: [
                { MENISCUS_TEAR_MEDIAL: { key: 'MENISCUS_TEAR_MEDIAL', value: 1, name: 'Meniscus Tear Medial' } },
                { MENISCUS_TEAR_LATERAL: { key: 'MENISCUS_TEAR_LATERAL', value: 2, name: 'Meniscus Tear Lateral' } },
                { CRUCIATE_INJURY_ANTERIOR: { key: 'CRUCIATE_INJURY_ANTERIOR', value: 3, name: 'Cruciate Injury Anterior' } },
                { CRUCIATE_INJURY_POSTERIOR: { key: 'CRUCIATE_INJURY_POSTERIOR', value: 4, name: 'Cruciate Injury Posterior' } },
                { BURSITIS_SUPRAPATELLAR: { key: 'BURSITIS_SUPRAPATELLAR', value: 5, name: 'Bursitis Suprapatellar' } },
                { BURSITIS_PREPATELLAR: { key: 'BURSITIS_PREPATELLAR', value: 6, name: 'Bursitis Prepatellar' } },
                { RADICULAR: { key: 'RADICULAR', value: 7, name: 'Radicular' } },
                { NONE: { key: 'NONE', value: 8, name: 'None' } }
            ],
        },
        {
            left_elbow: [
                { TENNIS_ELBOW: { key: 'TENNIS_ELBOW', value: 1, name: 'Tennis Elbow' } },
                { GOLFERS_ELBOW: { key: 'GOLFERS_ELBOW', value: 2, name: "Golfer's Elbow" } },
                { RADICULAR: { key: 'RADICULAR', value: 3, name: 'Radicular' } },
                { NONE: { key: 'NONE', value: 4, name: 'None' } }
            ],
        },
        {
            right_elbow: [
                { TENNIS_ELBOW: { key: 'TENNIS_ELBOW', value: 1, name: 'Tennis Elbow' } },
                { GOLFERS_ELBOW: { key: 'GOLFERS_ELBOW', value: 2, name: "Golfer's Elbow" } },
                { RADICULAR: { key: 'RADICULAR', value: 3, name: 'Radicular' } },
                { NONE: { key: 'NONE', value: 4, name: 'None' } }
            ],
        },
        {
            left_hand_wrist: [
                { DE_QUERVAINS_DISEASE: { key: 'DE_QUERVAINS_DISEASE', value: 1, name: "De Quervain's Disease" } },
                { GANGLION_CYST: { key: 'GANGLION_CYST', value: 2, name: "Ganglion Cyst" } },
                { FLEXOR_TENOSYNOVITIS_TRIGGER_THUMB: { key: 'FLEXOR_TENOSYNOVITIS_TRIGGER_THUMB', value: 3, name: "Flexor Tenosynovitis Trigger Thumb" } },
                { FLEXOR_TENOSYNOVITIS_TRIGGER_FINGER: { key: 'FLEXOR_TENOSYNOVITIS_TRIGGER_FINGER', value: 4, name: "Flexor Tenosynovitis Trigger Finger" } },
                { DUPUYTRENS_CONTRACTURE_CARPEL_TUNNEL_SYNDROME: { key: 'DUPUYTRENS_CONTRACTURE_CARPEL_TUNNEL_SYNDROME', value: 5, name: "Dupuytren's Contracture Carpel Tunnel Syndrome" } },
                { TRIANGULAR_FIBROCARTILAGE_COMPLEX_INJURIES: { key: 'TRIANGULAR_FIBROCARTILAGE_COMPLEX_INJURIES', value: 6, name: "Triangular Fibrocartilage Complex Injuries" } },
                { RADICULAR: { key: 'RADICULAR', value: 7, name: 'Radicular' } },
                { NONE: { key: 'NONE', value: 8, name: 'None' } }
            ],
        },
        {
            right_hand_wrist: [
                { DE_QUERVAINS_DISEASE: { key: 'DE_QUERVAINS_DISEASE', value: 1, name: "De Quervain's Disease" } },
                { GANGLION_CYST: { key: 'GANGLION_CYST', value: 2, name: "Ganglion Cyst" } },
                { FLEXOR_TENOSYNOVITIS_TRIGGER_THUMB: { key: 'FLEXOR_TENOSYNOVITIS_TRIGGER_THUMB', value: 3, name: "Flexor Tenosynovitis Trigger Thumb" } },
                { FLEXOR_TENOSYNOVITIS_TRIGGER_FINGER: { key: 'FLEXOR_TENOSYNOVITIS_TRIGGER_FINGER', value: 4, name: "Flexor Tenosynovitis Trigger Finger" } },
                { DUPUYTRENS_CONTRACTURE_CARPEL_TUNNEL_SYNDROME: { key: 'DUPUYTRENS_CONTRACTURE_CARPEL_TUNNEL_SYNDROME', value: 5, name: "Dupuytren's Contracture Carpel Tunnel Syndrome" } },
                { TRIANGULAR_FIBROCARTILAGE_COMPLEX_INJURIES: { key: 'TRIANGULAR_FIBROCARTILAGE_COMPLEX_INJURIES', value: 6, name: "Triangular Fibrocartilage Complex Injuries" } },
                { RADICULAR: { key: 'RADICULAR', value: 7, name: 'Radicular' } },
                { NONE: { key: 'NONE', value: 8, name: 'None' } }
            ],
        },
        {
            left_ankle_foot: [
                { PLANTAR_FASCIITIS: { key: 'PLANTAR_FASCIITIS', value: 1, name: "Plantar Fasciitis" } },
                { RETROCALCANEAL_BURSITIS: { key: 'RETROCALCANEAL_BURSITIS', value: 2, name: "Retrocalcaneal Bursitis" } },
                { TENDOACHILLES_TENDINOSIS: { key: 'TENDOACHILLES_TENDINOSIS', value: 3, name: "TendoAchilles Tendinosis" } },
                { CLAW_TOES: { key: 'CLAW_TOES', value: 4, name: "Claw Toes" } },
                { PAINFUL_BUNION_BIG_TOE: { key: 'PAINFUL_BUNION_BIG_TOE', value: 5, name: "Painful Bunion Big toe" } },
                { HALLUX_VAGUS: { key: 'HALLUX_VAGUS', value: 6, name: "Hallux Vagus" } },
                { HALLUX_RIGIDUS: { key: 'HALLUX_RIGIDUS', value: 7, name: "Hallux Rigidus" } },
                { RADICULAR: { key: 'RADICULAR', value: 8, name: 'Radicular' } },
                { NONE: { key: 'NONE', value: 9, name: 'None' } }
            ],
        },
        {
            right_ankle_foot: [
                { PLANTAR_FASCIITIS: { key: 'PLANTAR_FASCIITIS', value: 1, name: "Plantar Fasciitis" } },
                { RETROCALCANEAL_BURSITIS: { key: 'RETROCALCANEAL_BURSITIS', value: 2, name: "Retrocalcaneal Bursitis" } },
                { TENDOACHILLES_TENDINOSIS: { key: 'TENDOACHILLES_TENDINOSIS', value: 3, name: "TendoAchilles Tendinosis" } },
                { CLAW_TOES: { key: 'CLAW_TOES', value: 4, name: "Claw Toes" } },
                { PAINFUL_BUNION_BIG_TOE: { key: 'PAINFUL_BUNION_BIG_TOE', value: 5, name: "Painful Bunion Big toe" } },
                { HALLUX_VAGUS: { key: 'HALLUX_VAGUS', value: 6, name: "Hallux Vagus" } },
                { HALLUX_RIGIDUS: { key: 'HALLUX_RIGIDUS', value: 7, name: "Hallux Rigidus" } },
                { RADICULAR: { key: 'RADICULAR', value: 8, name: 'Radicular' } },
                { NONE: { key: 'NONE', value: 9, name: 'None' } }
            ],
        },

    ])
    useEffect(() => {
        if (centerId) {
            getTendinopathiesList(centerId, page, rowsPerPage)
        }
    }, [centerId]);
    useEffect(() => {
        getTendinopathiesList(centerId, page, rowsPerPage)
    }, [page]);
    const getTendinopathiesList = async (centerId, page, rowsPerPage) => {
        setIsLoader(true)
        const response = await getTendinopathiesListAPI(centerId, params?.patient_id, page, rowsPerPage, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setDiagnosisList(response?.data?.rows)
            setCount(response?.data?.count)

        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const handlePageChange = (newPage) => {
        setPage(newPage - 1);

    };
    const handleAddDiagnosisClick = () => {
        history.push(`/patient/tendinopathies/add/${params?.patient_id}`)
    }
    const handleClickEdit = (e, id) => {
        history.push(`/patient/tendinopathies/edit/${params?.patient_id}/${id}`)
    }
    const getNameofSelectedOptions = (ids, key) => {
        console.log(ids, 'ids')
        let allDdata = null
        if (diagnosisQuestions) {
            for (let a of diagnosisQuestions) {
                let keyValueData = Object.keys(a)[0]
                if (keyValueData) {
                    if (keyValueData == key) {
                        allDdata = a[keyValueData]
                    }
                }

            }
        }

        console.log(allDdata, 'allDdata')
        console.log(diagnosisQuestions, 'diagnosisQuestions')
        ids = ids.map(Number);
        if (allDdata) {
            const filteredData = allDdata.filter(item => {
                const value = Object.values(item)[0].value;
                return ids.includes(value);
            });
            console.log(filteredData, 'filteredData')
            // return filteredData
            const names = filteredData.map(item => Object.values(item)[0].name).join(', ');
            console.log(names, 'names')
            return names
        }
    }
    return (
        <div>
            {isLoader && (<Loader />)}
            {/* <TransitionGroup>
                <CSSTransition
                    component="div"
                    className="TabsAnimation"
                    appear={true}
                    timeout={0}
                    enter={false}
                    exit={false}>
                    <div>
                        <PageTitle
                            heading="Center List"
                            subheading="This is an example dashboard created using build-in elements and components."
                            icon="pe-7s-car icon-gradient bg-mean-fruit"
                        />
                    </div>
                </CSSTransition>
            </TransitionGroup> */}
            <BreadCrumb items={[
                { label: "Dashboard", url: "/" },
                { label: "Patients", url: "/patients" },
                { label: patientNameID, url: `/patient/dashboard/${params?.patient_id}` },
                { label: "Tendinopathies And Bursitis" }
            ]} />
            <Card className="main-card mb-3">
                <CardBody>
                    <CardTitle className="flex-space-between">Tendinopathies And Bursitis List
                        {(permissions.includes("PATIENT_CSREF_FORM_6_CREATE") || role == "SUPER_ADMIN") && (
                            <button className="btn btn-primary" onClick={() => handleAddDiagnosisClick()}>
                                Add Tendinopathies And Bursitis
                            </button>
                        )}
                    </CardTitle>
                    <div class="table-responsive">
                        <Table striped className="mb-0">
                            <thead>
                                <tr>
                                    <th>Left Shoulder</th>
                                    <th>Right Shoulder</th>
                                    <th>Left Knee</th>
                                    <th>Right Knee</th>
                                    <th>Left Elbow</th>
                                    <th>Right Elbow</th>
                                    <th>Left Hand/Wrist</th>
                                    <th>Right Hand/Wrist</th>
                                    <th>Left Ankle/Foot</th>
                                    <th>Right Ankle/Foot</th>
                                    <th>Created At</th>
                                    {(permissions.includes("PATIENT_CSREF_FORM_6_UPDATE") || role == "SUPER_ADMIN") && (
                                        <th>Action</th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {diagnosisList && diagnosisList.map((item, index) => {
                                    // console.log(getNameofSpinal(item?.spinal),'getNameofSpinal(item?.spinal)')
                                    // console.log(getNameofSpinal(item?.spinal).map(item => Object.values(item)[0].name).join(', '),'getNameofSpinal(item?.spinal)dfd')
                                    // let spinalName = getNameofSpinal(item?.spinal).map(item => Object.values(item)[0].name).join(', ')
                                    return (
                                        <tr key={index}>
                                            <td>{getNameofSelectedOptions(item?.left_shoulder, 'left_shoulder')}</td>
                                            <td>{getNameofSelectedOptions(item?.right_shoulder, 'right_shoulder')}</td>
                                            <td>{getNameofSelectedOptions(item?.left_knee, 'left_knee')}</td>
                                            <td>{getNameofSelectedOptions(item?.right_knee, 'right_knee')}</td>
                                            <td>{getNameofSelectedOptions(item?.left_elbow, 'left_elbow')}</td>
                                            <td>{getNameofSelectedOptions(item?.right_elbow, 'right_elbow')}</td>
                                            <td>{getNameofSelectedOptions(item?.left_hand_wrist, 'left_hand_wrist')}</td>
                                            <td>{getNameofSelectedOptions(item?.right_hand_wrist, 'right_hand_wrist')}</td>
                                            <td>{getNameofSelectedOptions(item?.left_ankle_foot, 'left_ankle_foot')}</td>
                                            <td>{getNameofSelectedOptions(item?.right_ankle_foot, 'right_ankle_foot')}</td>
                                            <td>{dateTimeFormate(item?.created_at)}</td>
                                            {((permissions.includes("PATIENT_CSREF_FORM_6_UPDATE") || role == "SUPER_ADMIN") && isToday(item?.created_at)) && (
                                                <td>
                                                    <i style={{ cursor: 'pointer' }} className="fa fa-edit" onClick={(e) => { handleClickEdit(e, item.id) }}></i>
                                                </td>
                                            )}
                                        </tr>
                                    )
                                })}


                            </tbody>
                        </Table>
                    </div>
                    {count > 10 && (
                        <Pagination
                            currentPage={page + 1}
                            totalPages={Math.ceil(count / rowsPerPage)}
                            onPageChange={handlePageChange}
                        />
                    )}
                </CardBody>

            </Card>
        </div>
    );
};

export default DiagnosisList;
