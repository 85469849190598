import React, { useState, useEffect } from 'react';
import {
    Row, Col,
    Card, CardBody,
    CardTitle, Form, FormGroup, Label, Input, Button, FormFeedback
} from 'reactstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { addPatientTreatmentAPI, getPatientTreatmentCountAPI, getPatientTreatmentDetailAPI, updatePatientTreatmentAPI } from './../../../api/network/Api';
import PageTitle from '../../../Layout/AppMain/PageTitle';
import axios from "axios";
import { toast, Bounce } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import Loader from '../../../api/helperComponents/Loader';
import BreadCrumb from '../../../api/helperComponents/BreadCrumb';
import userUtil from '../../../utils/UserUtils';
const CenterAdd = () => {
    const history = useHistory()
    const params = useParams()
    const cancelTokenSource = axios.CancelToken.source();
    const [questionValue, setQuestionValue] = useState({});
    const [isLoader, setIsLoader] = useState(false);
    const [showError, setShowError] = useState(false);
    const [visitNumber, setVisitNumber] = useState(0);
    const [refresh, setRefresh] = useState(false);
    const [customCreatedAt, setCustomCreatedAt] = useState(null);
    const [patientId, setPatientId] = useState(null);
    const [patientNameID, setPatientNameID] = useState(localStorage.getItem('patientNameID'));
    const [treatmentData, setTreatmentData] = useState({
        "duration": "540",
        "type": "A",
        "token": "1",
        "atlas_rotation_right": '0',
        "atlas_rotation_left": '0',
        "shoulder_level_left": "",
        "shoulder_level_right": "",
        "shoulder_weakness_left": "",
        "shoulder_weakness_right": "",
        "pelvic_level_left": "",
        "pelvic_level_right": "",
        "leg_weakness_left": "",
        "leg_weakness_right": "",
        "leg_length_diff_left": "",
        "leg_length_diff_right": "",
        "edema_cervical_spine_left": "No",
        "edema_cervical_spine_right": "No",
        "l_sacral_spine_l1": null,
        "l_sacral_spine_l2": null,
        "l_sacral_spine_l3": null,
        "l_sacral_spine_l4": null,
        "l_sacral_spine_l5": null,
        "edema_thoracic_spine_t1": "No",
        "edema_thoracic_spine_t2": "No",
        "edema_thoracic_spine_t3": "No",
        "edema_thoracic_spine_t4": "No",
        "edema_thoracic_spine_t5": "No",
        "edema_thoracic_spine_t6": "No",
        "edema_thoracic_spine_t7": "No",
        "edema_thoracic_spine_t8": "No",
        "edema_thoracic_spine_t9": "No",
        "edema_thoracic_spine_t10": "No",
        "edema_thoracic_spine_t11": "No",
        "edema_thoracic_spine_t12": "No",
        "cervical_range_motion_left": null,
        "cervical_range_motion_right": null,
        "position_examination_balance": null,
        "right_si": null,
        "left_si": null,
        "remark": null

    });
    const thirtyNumbers = Array.from({ length: 30 }, (_, i) => i);
    const fiveNumbers = Array.from({ length: 11 }, (_, i) => i * 0.5);;
    const tenNumbers = Array.from({ length: 10 }, (_, i) => i);
    const [centerId, setCenterId] = useState(JSON.parse(localStorage.getItem('userDetail'))?.center_id);
    useEffect(() => {
        if (params?.patient_id) {
            getPatientTreatmentCount(params?.patient_id)
        }
    }, [params?.patient_id]);
    useEffect(() => {
        if (params?.treatment_id) {
            getPatientTreatmentDetail(params?.treatment_id)
        }
    }, [params?.treatment_id]);
    useEffect(() => {
        if (visitNumber) {
            handleChangeOption("visit_number", visitNumber.toString());
        }
    }, [visitNumber]);
    const isvalid = () => {
        console.log(treatmentData, 'treatmentData')
        const hasNullOrEmpty = Object.values(treatmentData).some(value => value === null || value === "");
        if (hasNullOrEmpty) {
            return false
        } else {
            return true
        }
        // if (
        //     treatmentData['position_examination_balance'] == '' ||
        //     treatmentData['position_examination_balance'] == null ||
        //     treatmentData['remark'] == '' ||
        //     treatmentData['remark'] == null
        // ) {
        //     return false
        // } else {
        //     return true
        // }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (!isvalid()) {
            setShowError(true)
            toast("Please Fill all the fields", {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
            return
        } else {
            setShowError(false)
            if (params?.treatment_id) {
                handleUpdateHistory()
            } else {
                handleSubmitHistory()
            }
        }
    }
    const getPatientTreatmentCount = async () => {
        // return;
        setIsLoader(true)
        const response = await getPatientTreatmentCountAPI(centerId, params?.patient_id, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setVisitNumber(parseInt(response?.data?.count) + 1)
            handleChangeOption("visit_number", parseInt(response?.data?.count) + 1)

            // history.push(`/patient/medical-histories/${params?.patient_id}`)
        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const getPatientTreatmentDetail = async (treatmentId) => {
        // return;
        setIsLoader(true)
        const response = await getPatientTreatmentDetailAPI(centerId, treatmentId, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setVisitNumber(parseInt(response?.data?.visit_number) + 1)
            setPatientId(response?.data?.patient_info?.id)
            setTreatmentData({
                "duration": response?.data?.duration,
                "type": response?.data?.type,
                "token": response?.data?.token,
                "atlas_rotation_right": response?.data?.atlas_rotation_right,
                "atlas_rotation_left": response?.data?.atlas_rotation_left,
                "shoulder_level_left": response?.data?.shoulder_level_left,
                "shoulder_level_right": response?.data?.shoulder_level_right,
                "shoulder_weakness_left": response?.data?.shoulder_weakness_left,
                "shoulder_weakness_right": response?.data?.shoulder_weakness_right,
                "pelvic_level_left": response?.data?.pelvic_level_left,
                "pelvic_level_right": response?.data?.pelvic_level_right,
                "leg_weakness_left": response?.data?.leg_weakness_left,
                "leg_weakness_right": response?.data?.leg_weakness_right,
                "leg_length_diff_left": response?.data?.leg_length_diff_left,
                "leg_length_diff_right": response?.data?.leg_length_diff_right,
                "edema_cervical_spine_left": response?.data?.edema_cervical_spine_left,
                "edema_cervical_spine_right": response?.data?.edema_cervical_spine_right,
                "l_sacral_spine_l1": response?.data?.l_sacral_spine_l1,
                "l_sacral_spine_l2": response?.data?.l_sacral_spine_l2,
                "l_sacral_spine_l3": response?.data?.l_sacral_spine_l3,
                "l_sacral_spine_l4": response?.data?.l_sacral_spine_l4,
                "l_sacral_spine_l5": response?.data?.l_sacral_spine_l5,
                "edema_thoracic_spine_t1": response?.data?.edema_thoracic_spine_t1,
                "edema_thoracic_spine_t2": response?.data?.edema_thoracic_spine_t2,
                "edema_thoracic_spine_t3": response?.data?.edema_thoracic_spine_t3,
                "edema_thoracic_spine_t4": response?.data?.edema_thoracic_spine_t4,
                "edema_thoracic_spine_t5": response?.data?.edema_thoracic_spine_t5,
                "edema_thoracic_spine_t6": response?.data?.edema_thoracic_spine_t6,
                "edema_thoracic_spine_t7": response?.data?.edema_thoracic_spine_t7,
                "edema_thoracic_spine_t8": response?.data?.edema_thoracic_spine_t8,
                "edema_thoracic_spine_t9": response?.data?.edema_thoracic_spine_t9,
                "edema_thoracic_spine_t10": response?.data?.edema_thoracic_spine_t10,
                "edema_thoracic_spine_t11": response?.data?.edema_thoracic_spine_t11,
                "edema_thoracic_spine_t12": response?.data?.edema_thoracic_spine_t12,
                "cervical_range_motion_left": response?.data?.cervical_range_motion_left,
                "cervical_range_motion_right": response?.data?.cervical_range_motion_right,
                "position_examination_balance": response?.data?.position_examination_balance,
                "visit_number": response?.data?.visit_number,
                "right_si": response?.data?.right_si,
                "left_si": response?.data?.left_si,
                "remark": response?.data?.remark,

            })
            setCustomCreatedAt(response?.data?.created_at?.split('T')[0])
        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const handleSubmitHistory = async () => {
        // return;
        setIsLoader(true)
        window.scrollTo({ top: 0, behavior: 'smooth' });

        treatmentData['customCreatedAt'] = customCreatedAt != '' ? customCreatedAt : null
        const response = await addPatientTreatmentAPI(centerId, params?.patient_id, treatmentData, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            toast("Treatment added Successfully", {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'success'
            });
            history.push(`/patient/dashboard/${params?.patient_id}`)
            // history.push(`/patient/medical-histories/${params?.patient_id}`)
        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const handleUpdateHistory = async () => {
        // return;
        setIsLoader(true)
        window.scrollTo({ top: 0, behavior: 'smooth' });
        treatmentData['id'] = params?.treatment_id
        treatmentData['customCreatedAt'] = customCreatedAt != '' ? customCreatedAt : null
        const response = await updatePatientTreatmentAPI(centerId, treatmentData, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            toast("Treatment updated Successfully", {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'success'
            });
            history.push(`/patient/dashboard/${params?.patient_id}`)
            // history.push(`/patient/medical-histories/${params?.patient_id}`)
        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const handleClick = (e, data, optionData) => {
        if (data?.type == 'single-select') {
            questionValue[data?.key] = optionData
            setQuestionValue(questionValue)
        } else {
            var selectedValues = [];
            var checkboxes = document.getElementsByName(data?.key);

            for (var i = 0; i < checkboxes.length; i++) {
                if (checkboxes[i].checked) {
                    console.log(checkboxes[i].value, 'checkboxes[i].value')
                    selectedValues.push(checkboxes[i].value);
                }
            }
            questionValue[data?.key] = selectedValues
            setQuestionValue(questionValue)
        }
        console.log(questionValue, 'questionValue')
    }
    const isEmptyObject = (obj) => {
        for (let key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }
    const handleChangeValue = (e) => {
        let value = e.target.value
        if ([e.target.name] == "shoulder_level_left" || [e.target.name] == "shoulder_level_right" || [e.target.name] == "pelvic_level_right" || [e.target.name] == "pelvic_level_left") {
            const regex = /^(?:[0-9]|[1-2][0-9]|30)$/;
            if (regex.test(value) || value === '') {
                setTreatmentData(prevData => ({
                    ...prevData,
                    [e.target.name]: e.target.value
                }))
            }
        } else if ([e.target.name] == "shoulder_weakness_left" || [e.target.name] == "shoulder_weakness_right" || [e.target.name] == "leg_weakness_left" || [e.target.name] == "leg_weakness_right") {
            // const regex = /^[0-5]$/;
            // if (regex.test(value) || value === '') {
            setTreatmentData(prevData => ({
                ...prevData,
                [e.target.name]: e.target.value
            }))
            // }
        } else if ([e.target.name] == "leg_length_diff_left" || [e.target.name] == "leg_length_diff_right") {
            const regex = /^(10|[0-9])$/;
            if (regex.test(value) || value === '') {
                setTreatmentData(prevData => ({
                    ...prevData,
                    [e.target.name]: e.target.value
                }))
            }
        } else {
            setTreatmentData(prevData => ({
                ...prevData,
                [e.target.name]: e.target.value
            }))
        }

    }
    const handleChangeOption = (key, value) => {
        console.log(treatmentData['atlas_rotation_left'], 'atlas_rotation_left')
        console.log(value, 'value handleChangeOption')
        console.log(key, 'key handleChangeOption')
        setTreatmentData(prevData => ({
            ...prevData,
            [key]: value
        }))

        // if(key == 'atlas_rotation_left'){
        //     if(treatmentData['atlas_rotation_left'] == value){
        //         console.log('atlas_rotation_left value matched')
        //         treatmentData['atlas_rotation_left'] = "0"
        //     }
        // }
        // if(key == 'atlas_rotation_right'){
        //     if(treatmentData['atlas_rotation_right'] == value){
        //         treatmentData['atlas_rotation_right'] = "0"
        //     }
        // }


        console.log(treatmentData, 'treatmentData treatmentDatatreatmentData')
        // setTreatmentData(treatmentData)
    }
    const handleChangeOptionEdemaThoracicSpine = (key, value) => {
        // setTreatmentData(prevData => ({ ...prevData, "edema_thoracic_spine_t1": "No" }))
        // setTreatmentData(prevData => ({ ...prevData, "edema_thoracic_spine_t2": "No" }))
        // setTreatmentData(prevData => ({ ...prevData, "edema_thoracic_spine_t3": "No" }))
        // setTreatmentData(prevData => ({ ...prevData, "edema_thoracic_spine_t4": "No" }))
        // setTreatmentData(prevData => ({ ...prevData, "edema_thoracic_spine_t5": "No" }))
        // setTreatmentData(prevData => ({ ...prevData, "edema_thoracic_spine_t6": "No" }))
        // setTreatmentData(prevData => ({ ...prevData, "edema_thoracic_spine_t7": "No" }))
        // setTreatmentData(prevData => ({ ...prevData, "edema_thoracic_spine_t8": "No" }))
        // setTreatmentData(prevData => ({ ...prevData, "edema_thoracic_spine_t9": "No" }))
        // setTreatmentData(prevData => ({ ...prevData, "edema_thoracic_spine_t10": "No" }))
        // setTreatmentData(prevData => ({ ...prevData, "edema_thoracic_spine_t11": "No" }))
        // setTreatmentData(prevData => ({ ...prevData, "edema_thoracic_spine_t12": "No" }))
        setTreatmentData(prevData => ({
            ...prevData,
            [key]: value
        }))
    }
    return (
        <div>
            {refresh}
            {isLoader && (<Loader />)}
            {console.log(treatmentData, 'treatmentData')}
            <Row className="justify-content-center treatment-main-responsive">
                {console.log(questionValue, 'questionValue')}
                <Col md="10" sm="12" className="treatment-main-responsive">
                    <BreadCrumb items={[
                        { label: "Dashboard", url: "/" },
                        { label: "Patients", url: "/patients" },
                        { label: patientNameID, url: `/patient/dashboard/${params?.patient_id}` },
                        { label: "Treatment", url: `/patient/treatments/list/${params?.patient_id ? params?.patient_id : patientId}` },
                        { label: `${params?.treatment_id ? " Edit" : " Add"}  Patient Treatment` },
                    ]} />
                    <Card className="main-card mb-3">
                        <CardBody>
                            <CardTitle className="flex-space-between">{params?.treatment_id ? "Edit" : "Add"} Patient Treatment</CardTitle>
                            <Row className="justify-content-center treatment-responsive">
                                <Col md="3" >
                                    {console.log(visitNumber, 'visitNumbervisitNumber')}
                                    <FormGroup>
                                        <Label for="f_name">Visit #</Label>
                                        <Input type="text" name="visit" id="visit" value={visitNumber}
                                            onChange={(e) => {
                                                handleChangeOption("visit_number", e.target.value);
                                                setVisitNumber(e.target.value);
                                            }}
                                            placeholder="Visit Number" />
                                    </FormGroup>
                                </Col>

                                <Col md="9" >
                                    <FormGroup>
                                        <Label for="f_name" style={{ margin: '4px 10px' }}>Position of Examining a Balance<span style={{ color: 'red' }}>*</span></Label>
                                        <Row className="margin-0">
                                            <Col md="4" className="padding-0">
                                                <div
                                                    style={{ height: '38px', borderRadius: '5px', margin: ' 0px 10px' }}
                                                    className={`center-align border-1 custom-radio ${treatmentData["position_examination_balance"] == "Independent Standing" ? 'patient_treatment_selected' : ''}`}
                                                    onClick={(e) => { handleChangeOption("position_examination_balance", "Independent Standing") }}>
                                                    Independent Standing
                                                </div>
                                            </Col>
                                            <Col md="4" className="padding-0">
                                                <div
                                                    style={{ height: '38px', borderRadius: '5px', margin: ' 0px 10px' }}
                                                    className={`center-align border-1 custom-radio ${treatmentData["position_examination_balance"] == "Independent Sitting" ? 'patient_treatment_selected' : ''}`}
                                                    onClick={(e) => { handleChangeOption("position_examination_balance", "Independent Sitting") }}>
                                                    Independent Sitting
                                                </div>
                                            </Col>
                                            <Col md="4" className="padding-0">
                                                <div
                                                    style={{ height: '38px', borderRadius: '5px', margin: ' 0px 10px' }}
                                                    className={`center-align border-1 custom-radio ${treatmentData["position_examination_balance"] == "Not Applicable" ? 'patient_treatment_selected' : ''}`}
                                                    onClick={(e) => { handleChangeOption("position_examination_balance", "Not Applicable") }}>
                                                    Not Applicable
                                                </div>
                                            </Col>
                                        </Row>
                                        {/* <Input type="select" name="position_examination_balance" id="position_examination_balance" value={treatmentData['position_examination_balance']} onChange={(e) => { handleChangeValue(e) }}>
                                            <option value="">Select Position of Examining a Balance</option>
                                            <option value="Independent Standing" selected={treatmentData['position_examination_balance'] == 'Independent Standing' ? true : false}>Independent Standing</option>
                                            <option value="Independent Sitting" selected={treatmentData['position_examination_balance'] == 'Independent Sitting' ? true : false}>Independent Sitting</option>
                                            <option value="Not Applicable" selected={treatmentData['position_examination_balance'] == 'Not Applicable' ? true : false}>Not Applicable</option>
                                        </Input> */}

                                        {(treatmentData["position_examination_balance"] === null || treatmentData["position_examination_balance"] == "") && showError && (
                                            <span className="field-error">Select Position of Examining a Balance to Continue</span>
                                        )}
                                    </FormGroup>
                                </Col>
                                <Col md="6" >
                                    <FormGroup>
                                        <Label for="f_name">Atlas Rotation:<span style={{ color: 'red' }}>*</span></Label>
                                        <Row className="margin-0">
                                            <Col md="6" className="padding-0">
                                                <div
                                                    className={`center-align border-1 custom-radio ${treatmentData["atlas_rotation_left"] != '0' && treatmentData["atlas_rotation_left"] != null ? 'patient_treatment_selected' : ''}`}>
                                                    Left
                                                </div>
                                                <Row className="margin-0">
                                                    <Col md="6" className="padding-0">
                                                        <div
                                                            className={`center-align border-1 custom-radio ${treatmentData["atlas_rotation_left"] == "Anterior" ? 'patient_treatment_selected' : ''}`}
                                                            onClick={(e) => { handleChangeOption("atlas_rotation_left", "Anterior") }}>
                                                            Anterior
                                                        </div>
                                                    </Col>
                                                    <Col md="6" className="padding-0">
                                                        <div
                                                            className={`center-align border-1 custom-radio ${treatmentData["atlas_rotation_left"] == "Posterior" ? 'patient_treatment_selected' : ''}`}
                                                            onClick={(e) => { handleChangeOption("atlas_rotation_left", "Posterior") }}>
                                                            Posterior
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md="6" className="padding-0">
                                                <div
                                                    className={`center-align border-1 custom-radio ${treatmentData["atlas_rotation_right"] != '0' && treatmentData["atlas_rotation_right"] != null ? 'patient_treatment_selected' : ''}`}>
                                                    Right
                                                </div>
                                                <Row className="margin-0">
                                                    <Col md="6" className="padding-0">
                                                        <div name="atlas_rotation_right" value="Anterior"
                                                            className={`center-align border-1 custom-radio ${treatmentData["atlas_rotation_right"] == "Anterior" ? 'patient_treatment_selected' : ''}`}
                                                            onClick={(e) => { handleChangeOption("atlas_rotation_right", "Anterior") }}
                                                        >
                                                            Anterior
                                                        </div>
                                                    </Col>
                                                    <Col md="6" className="padding-0">
                                                        <div
                                                            className={`center-align border-1 custom-radio ${treatmentData["atlas_rotation_right"] == "Posterior" ? 'patient_treatment_selected' : ''}`}
                                                            onClick={(e) => { handleChangeOption("atlas_rotation_right", "Posterior") }}
                                                        >
                                                            Posterior
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>

                                        </Row>
                                    </FormGroup>
                                </Col>
                                <Col md="3" >
                                    <FormGroup>
                                        <Label for="f_name">Shoulder Level<span style={{ color: 'red' }}>*</span></Label>
                                        {console.log(treatmentData["shoulder_level_right"], 'ajbdsajhsbdjashbd')}
                                        <Row className="margin-0">
                                            <Col md="6" className="padding-0">
                                                <div className={`center-align border-1 custom-radio`}>Right</div>
                                                <Row className="margin-0">
                                                    <Col md="12" className="padding-0">
                                                        <Input className={treatmentData["shoulder_level_right"] != "" ? "custom-select-treatment custom-select-treatment-selected " : "custom-select-treatment"} type="select" name="shoulder_level_right" id="shoulder_level_right" value={treatmentData["shoulder_level_right"]} onChange={(e) => { handleChangeValue(e) }}>
                                                            <option value="">Select</option>
                                                            {thirtyNumbers.map((item, index) => (
                                                                <option value={item}>{item}</option>
                                                            ))}
                                                        </Input>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md="6" className="padding-0">
                                                <div className={`center-align border-1 custom-radio`}>Left</div>
                                                <Row className="margin-0">
                                                    <Col md="12" className="padding-0">
                                                        <Input className={treatmentData["shoulder_level_left"] != "" ? "custom-select-treatment custom-select-treatment-selected " : "custom-select-treatment"}
                                                            type="select" name="shoulder_level_left" id="shoulder_level_left" value={treatmentData["shoulder_level_left"]} onChange={(e) => { handleChangeValue(e) }} placeholder="" >
                                                            <option value="">Select</option>
                                                            {thirtyNumbers.map((item, index) => (
                                                                <option value={item}>{item}</option>
                                                            ))}
                                                        </Input>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>

                                <Col md="3" >
                                    <FormGroup>
                                        <Label for="f_name">Shoulder Weakness<span style={{ color: 'red' }}>*</span></Label>
                                        <Row className="margin-0">
                                            <Col md="6" className="padding-0">
                                                <div className={`center-align border-1 custom-radio`}>Right</div>
                                                <Row className="margin-0">
                                                    <Col md="12" className="padding-0">
                                                        <Input
                                                            className={treatmentData["shoulder_weakness_right"] != "" ? "custom-select-treatment custom-select-treatment-selected " : "custom-select-treatment"}
                                                            type="select" name="shoulder_weakness_right" id="shoulder_weakness_right" value={treatmentData["shoulder_weakness_right"]} onChange={(e) => { handleChangeValue(e) }} placeholder="" >
                                                            <option value="">Select</option>
                                                            {fiveNumbers.map((item, index) => (
                                                                <option value={item}>{item}</option>
                                                            ))}
                                                        </Input>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md="6" className="padding-0">
                                                <div className={`center-align border-1 custom-radio`}>Left</div>
                                                <Row className="margin-0">
                                                    <Col md="12" className="padding-0">
                                                        <Input
                                                            className={treatmentData["shoulder_weakness_left"] != "" ? "custom-select-treatment custom-select-treatment-selected " : "custom-select-treatment"}
                                                            type="select" name="shoulder_weakness_left" id="shoulder_weakness_left" value={treatmentData["shoulder_weakness_left"]} onChange={(e) => { handleChangeValue(e) }} placeholder="" >
                                                            <option value="">Select</option>
                                                            {fiveNumbers.map((item, index) => (
                                                                <option value={item}>{item}</option>
                                                            ))}
                                                        </Input>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                                <Col md="3" >
                                    <FormGroup>
                                        <Label for="f_name">Pelvic Level<span style={{ color: 'red' }}>*</span></Label>
                                        <Row className="margin-0">
                                            <Col md="6" className="padding-0">
                                                <div className={`center-align border-1 custom-radio`}>Right</div>
                                                <Row className="margin-0">
                                                    <Col md="12" className="padding-0">
                                                        <Input
                                                            className={treatmentData["pelvic_level_right"] != "" ? "custom-select-treatment custom-select-treatment-selected " : "custom-select-treatment"}
                                                            type="select" name="pelvic_level_right" id="pelvic_level_right" value={treatmentData["pelvic_level_right"]} onChange={(e) => { handleChangeValue(e) }} placeholder="" >
                                                            <option value="">Select</option>
                                                            {thirtyNumbers.map((item, index) => (
                                                                <option value={item}>{item}</option>
                                                            ))}
                                                        </Input>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md="6" className="padding-0">
                                                <div className={`center-align border-1 custom-radio`}>Left</div>
                                                <Row className="margin-0">
                                                    <Col md="12" className="padding-0">
                                                        <Input
                                                            className={treatmentData["pelvic_level_left"] != "" ? "custom-select-treatment custom-select-treatment-selected " : "custom-select-treatment"}
                                                            type="select" name="pelvic_level_left" id="pelvic_level_left" value={treatmentData["pelvic_level_left"]} onChange={(e) => { handleChangeValue(e) }} placeholder="" >
                                                            <option value="">Select</option>
                                                            {thirtyNumbers.map((item, index) => (
                                                                <option value={item}>{item}</option>
                                                            ))}
                                                        </Input>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                                <Col md="3" >
                                    <FormGroup>
                                        <Label for="f_name">Leg Weakness<span style={{ color: 'red' }}>*</span></Label>
                                        <Row className="margin-0">
                                            <Col md="6" className="padding-0">
                                                <div className={`center-align border-1 custom-radio`}>Right</div>
                                                <Row className="margin-0">
                                                    <Col md="12" className="padding-0">
                                                        <Input
                                                            className={treatmentData["leg_weakness_right"] != "" ? "custom-select-treatment custom-select-treatment-selected " : "custom-select-treatment"}
                                                            type="select" name="leg_weakness_right" id="leg_weakness_right" value={treatmentData["leg_weakness_right"]} onChange={(e) => { handleChangeValue(e) }} placeholder="" >
                                                            <option value="">Select</option>
                                                            {fiveNumbers.map((item, index) => (
                                                                <option value={item}>{item}</option>
                                                            ))}
                                                        </Input>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md="6" className="padding-0">
                                                <div className={`center-align border-1 custom-radio`}>Left</div>
                                                <Row className="margin-0">
                                                    <Col md="12" className="padding-0">
                                                        <Input
                                                            className={treatmentData["leg_weakness_left"] != "" ? "custom-select-treatment custom-select-treatment-selected " : "custom-select-treatment"}
                                                            type="select" name="leg_weakness_left" id="leg_weakness_left" value={treatmentData["leg_weakness_left"]} onChange={(e) => { handleChangeValue(e) }} placeholder="" >
                                                            <option value="">Select</option>
                                                            {fiveNumbers.map((item, index) => (
                                                                <option value={item}>{item}</option>
                                                            ))}
                                                        </Input>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                                <Col md="3" >
                                    <FormGroup>
                                        <Label for="f_name">Leg Length Diff<span style={{ color: 'red' }}>*</span></Label>
                                        <Row className="margin-0">
                                            <Col md="6" className="padding-0">
                                                <div className={`center-align border-1 custom-radio`}>Right</div>
                                                <Row className="margin-0">
                                                    <Col md="12" className="padding-0">
                                                        <Input
                                                            className={treatmentData["leg_length_diff_right"] != "" ? "custom-select-treatment custom-select-treatment-selected " : "custom-select-treatment"}
                                                            type="select" name="leg_length_diff_right" id="leg_length_diff_right" value={treatmentData["leg_length_diff_right"]} onChange={(e) => { handleChangeValue(e) }} placeholder="" >
                                                            <option value="">Select</option>
                                                            {tenNumbers.map((item, index) => (
                                                                <option value={item}>{item}</option>
                                                            ))}
                                                        </Input>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md="6" className="padding-0">
                                                <div className={`center-align border-1 custom-radio`}>Left</div>
                                                <Row className="margin-0">
                                                    <Col md="12" className="padding-0">
                                                        <Input
                                                            className={treatmentData["leg_length_diff_left"] != "" ? "custom-select-treatment custom-select-treatment-selected " : "custom-select-treatment"}
                                                            type="select" name="leg_length_diff_left" id="leg_length_diff_left" value={treatmentData["leg_length_diff_left"]} onChange={(e) => { handleChangeValue(e) }} placeholder="" >
                                                            <option value="">Select</option>
                                                            {tenNumbers.map((item, index) => (
                                                                <option value={item}>{item}</option>
                                                            ))}
                                                        </Input>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                                <Col md="3" >
                                    <FormGroup>
                                        <Label for="f_name">Edemas Cervical Spine<span style={{ color: 'red' }}>*</span></Label>
                                        <Row className="margin-0">
                                            <Col md="6" className="padding-0">
                                                <div className={`center-align border-1 custom-radio`}>Right</div>
                                                <Row className="margin-0">
                                                    <Col md="12" className="padding-0">
                                                        <Input
                                                            className={treatmentData["edema_cervical_spine_right"] != "No" ? "custom-select-treatment custom-select-treatment-selected " : "custom-select-treatment"}
                                                            type="select" name="edema_cervical_spine_right" id="edema_cervical_spine_right" value={treatmentData['edema_cervical_spine_right']} onChange={(e) => { handleChangeValue(e) }}>
                                                            <option value="">Select</option>
                                                            <option value="Nil" selected={treatmentData['edema_cervical_spine_right'] == 'Nil' ? true : false}>Nil</option>
                                                            <option value="Mild" selected={treatmentData['edema_cervical_spine_right'] == 'Mild' ? true : false}>Mild</option>
                                                            <option value="Moderate" selected={treatmentData['edema_cervical_spine_right'] == 'Moderate' ? true : false}>Moderate</option>
                                                            <option value="Severe" selected={treatmentData['edema_cervical_spine_right'] == 'Severe' ? true : false}>Severe</option>
                                                            <option value="Very Severe" selected={treatmentData['edema_cervical_spine_right'] == 'Very Severe' ? true : false}>Very Severe</option>
                                                        </Input>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md="6" className="padding-0">
                                                <div className={`center-align border-1 custom-radio`}>Left</div>
                                                <Row className="margin-0">
                                                    <Col md="12" className="padding-0">
                                                        <Input
                                                            className={treatmentData["edema_cervical_spine_left"] != "No" ? "custom-select-treatment custom-select-treatment-selected " : "custom-select-treatment"}
                                                            type="select" name="edema_cervical_spine_left" id="edema_cervical_spine_left" value={treatmentData['edema_cervical_spine_left']} onChange={(e) => { handleChangeValue(e) }}>
                                                            <option value="">Select</option>
                                                            <option value="Nil" selected={treatmentData['edema_cervical_spine_left'] == 'Nil' ? true : false}>Nil</option>
                                                            <option value="Mild" selected={treatmentData['edema_cervical_spine_left'] == 'Mild' ? true : false}>Mild</option>
                                                            <option value="Moderate" selected={treatmentData['edema_cervical_spine_left'] == 'Moderate' ? true : false}>Moderate</option>
                                                            <option value="Severe" selected={treatmentData['edema_cervical_spine_left'] == 'Severe' ? true : false}>Severe</option>
                                                            <option value="Very Severe" selected={treatmentData['edema_cervical_spine_left'] == 'Very Severe' ? true : false}>Very Severe</option>
                                                        </Input>
                                                    </Col>
                                                </Row>
                                            </Col>



                                        </Row>
                                    </FormGroup>
                                </Col>
                                <Col md="6" >
                                    <FormGroup>
                                        <Label for="f_name">Cervical Range of Motion<span style={{ color: 'red' }}>*</span></Label>
                                        <Row className="margin-0">
                                            <Col md="12" className="padding-0">
                                                <Row className="margin-0">
                                                    <Col md="2" className="padding-0">
                                                        <div style={{ height: '38px', padding: '5px' }}
                                                            className={`center-align border-1 custom-radio`}>
                                                            Right
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="padding-0">
                                                        <div
                                                            className={`center-align border-1 custom-radio ${treatmentData["cervical_range_motion_right"] == "Nil" ? 'patient_treatment_selected' : ''}`}
                                                            onClick={(e) => { handleChangeOption("cervical_range_motion_right", "Nil") }}>
                                                            <p>1</p>
                                                            <p>90°</p>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="padding-0">
                                                        <div
                                                            className={`center-align border-1 custom-radio ${treatmentData["cervical_range_motion_right"] == "Mild" ? 'patient_treatment_selected' : ''}`}
                                                            onClick={(e) => { handleChangeOption("cervical_range_motion_right", "Mild") }}>
                                                            <p>2</p>
                                                            <p>60°</p>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="padding-0">
                                                        <div
                                                            className={`center-align border-1 custom-radio ${treatmentData["cervical_range_motion_right"] == "Moderate" ? 'patient_treatment_selected' : ''}`}
                                                            onClick={(e) => { handleChangeOption("cervical_range_motion_right", "Moderate") }}>
                                                            <p>3</p>
                                                            <p>30°</p>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="padding-0">
                                                        <div
                                                            className={`center-align border-1 custom-radio ${treatmentData["cervical_range_motion_right"] == "Severe" ? 'patient_treatment_selected' : ''}`}
                                                            onClick={(e) => { handleChangeOption("cervical_range_motion_right", "Severe") }}>
                                                            <p>4</p>
                                                            <p>{"<30°"}</p>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="padding-0">
                                                        <div
                                                            className={`center-align border-1 custom-radio ${treatmentData["cervical_range_motion_right"] == "Very Severe" ? 'patient_treatment_selected' : ''}`}
                                                            onClick={(e) => { handleChangeOption("cervical_range_motion_right", "Very Severe") }}>
                                                            <p>5</p>
                                                            <p>{"<=10°"}</p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>



                                            <Col md="12" className="padding-0">
                                                <Row className="margin-0">
                                                    <Col md="2" className="padding-0">
                                                        <div style={{ height: '38px', padding: '5px' }}
                                                            className={`center-align border-1 custom-radio`}>
                                                            Left
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="padding-0">
                                                        <div
                                                            className={`center-align border-1 custom-radio ${treatmentData["cervical_range_motion_left"] == "Nil" ? 'patient_treatment_selected' : ''}`}
                                                            onClick={(e) => { handleChangeOption("cervical_range_motion_left", "Nil") }}>
                                                            <p>1</p>
                                                            <p>90°</p>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="padding-0">
                                                        <div
                                                            className={`center-align border-1 custom-radio ${treatmentData["cervical_range_motion_left"] == "Mild" ? 'patient_treatment_selected' : ''}`}
                                                            onClick={(e) => { handleChangeOption("cervical_range_motion_left", "Mild") }}>
                                                            <p>2</p>
                                                            <p>60°</p>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="padding-0">
                                                        <div
                                                            className={`center-align border-1 custom-radio ${treatmentData["cervical_range_motion_left"] == "Moderate" ? 'patient_treatment_selected' : ''}`}
                                                            onClick={(e) => { handleChangeOption("cervical_range_motion_left", "Moderate") }}>
                                                            <p>3</p>
                                                            <p>30°</p>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="padding-0">
                                                        <div
                                                            className={`center-align border-1 custom-radio ${treatmentData["cervical_range_motion_left"] == "Severe" ? 'patient_treatment_selected' : ''}`}
                                                            onClick={(e) => { handleChangeOption("cervical_range_motion_left", "Severe") }}>
                                                            <p>4</p>
                                                            <p>{"<30°"}</p>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="padding-0">
                                                        <div
                                                            className={`center-align border-1 custom-radio ${treatmentData["cervical_range_motion_left"] == "Very Severe" ? 'patient_treatment_selected' : ''}`}
                                                            onClick={(e) => { handleChangeOption("cervical_range_motion_left", "Very Severe") }}>
                                                            <p>5</p>
                                                            <p>{"<=10°"}</p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>

                                        </Row>
                                    </FormGroup>
                                </Col>


                                <Col md="6" >
                                    <FormGroup>
                                        <Label for="f_name">Edema Thoracic Spine<span style={{ color: 'red' }}>*</span></Label>
                                        <Row className="margin-0">
                                            <Col md="12" className="padding-0">
                                                <Row className="margin-0">
                                                    <Col md="2" className="padding-0">
                                                        <div
                                                            className={`center-align border-1 custom-radio font-size-14`}>
                                                            <p>T1</p>
                                                            <Input
                                                                style={{ fontSize: '12px' }}
                                                                className={treatmentData["edema_thoracic_spine_t1"] != 'No' ? "custom-select-treatment custom-select-treatment-selected " : "custom-select-treatment"}
                                                                type="select" name="edema_thoracic_spine_t1" id="edema_thoracic_spine_t1" value={treatmentData['edema_thoracic_spine_t1']} onChange={(e) => { handleChangeValue(e) }}>
                                                                <option value="">Select</option>
                                                                <option value="Nil" selected={treatmentData['edema_thoracic_spine_t1'] == 'Nil' ? true : false}>Nil</option>
                                                                <option value="Mild" selected={treatmentData['edema_thoracic_spine_t1'] == 'Mild' ? true : false}>Mild</option>
                                                                <option value="Moderate" selected={treatmentData['edema_thoracic_spine_t1'] == 'Moderate' ? true : false}>Moderate</option>
                                                                <option value="Severe" selected={treatmentData['edema_thoracic_spine_t1'] == 'Severe' ? true : false}>Severe</option>
                                                                <option value="Very Severe" selected={treatmentData['edema_thoracic_spine_t1'] == 'Very Severe' ? true : false}>Very Severe</option>
                                                            </Input>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="padding-0">
                                                        <div
                                                            className={`center-align border-1 custom-radio font-size-14 `}>
                                                            <p>T2</p>
                                                            <Input
                                                                style={{ fontSize: '12px' }}
                                                                className={treatmentData["edema_thoracic_spine_t2"] != 'No' ? "custom-select-treatment custom-select-treatment-selected " : "custom-select-treatment"}
                                                                type="select" name="edema_thoracic_spine_t2" id="edema_thoracic_spine_t2" value={treatmentData['edema_thoracic_spine_t2']} onChange={(e) => { handleChangeValue(e) }}>
                                                                <option value="">Select</option>
                                                                <option value="Nil" selected={treatmentData['edema_thoracic_spine_t2'] == 'Nil' ? true : false}>Nil</option>
                                                                <option value="Mild" selected={treatmentData['edema_thoracic_spine_t2'] == 'Mild' ? true : false}>Mild</option>
                                                                <option value="Moderate" selected={treatmentData['edema_thoracic_spine_t2'] == 'Moderate' ? true : false}>Moderate</option>
                                                                <option value="Severe" selected={treatmentData['edema_thoracic_spine_t2'] == 'Severe' ? true : false}>Severe</option>
                                                                <option value="Very Severe" selected={treatmentData['edema_thoracic_spine_t2'] == 'Very Severe' ? true : false}>Very Severe</option>
                                                            </Input>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="padding-0">
                                                        <div
                                                            className={`center-align border-1 custom-radio font-size-14 `}>
                                                            <p>T3</p>
                                                            <Input
                                                                style={{ fontSize: '12px' }}
                                                                className={treatmentData["edema_thoracic_spine_t3"] != 'No' ? "custom-select-treatment custom-select-treatment-selected " : "custom-select-treatment"}
                                                                type="select" name="edema_thoracic_spine_t3" id="edema_thoracic_spine_t3" value={treatmentData['edema_thoracic_spine_t3']} onChange={(e) => { handleChangeValue(e) }}>
                                                                <option value="">Select</option>
                                                                <option value="Nil" selected={treatmentData['edema_thoracic_spine_t3'] == 'Nil' ? true : false}>Nil</option>
                                                                <option value="Mild" selected={treatmentData['edema_thoracic_spine_t3'] == 'Mild' ? true : false}>Mild</option>
                                                                <option value="Moderate" selected={treatmentData['edema_thoracic_spine_t3'] == 'Moderate' ? true : false}>Moderate</option>
                                                                <option value="Severe" selected={treatmentData['edema_thoracic_spine_t3'] == 'Severe' ? true : false}>Severe</option>
                                                                <option value="Very Severe" selected={treatmentData['edema_thoracic_spine_t3'] == 'Very Severe' ? true : false}>Very Severe</option>
                                                            </Input>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="padding-0">
                                                        <div
                                                            className={`center-align border-1 custom-radio font-size-14 `}>
                                                            <p>T4</p>
                                                            <Input
                                                                style={{ fontSize: '12px' }}
                                                                className={treatmentData["edema_thoracic_spine_t4"] != 'No' ? "custom-select-treatment custom-select-treatment-selected " : "custom-select-treatment"}
                                                                type="select" name="edema_thoracic_spine_t4" id="edema_thoracic_spine_t4" value={treatmentData['edema_thoracic_spine_t4']} onChange={(e) => { handleChangeValue(e) }}>
                                                                <option value="">Select</option>
                                                                <option value="Nil" selected={treatmentData['edema_thoracic_spine_t4'] == 'Nil' ? true : false}>Nil</option>
                                                                <option value="Mild" selected={treatmentData['edema_thoracic_spine_t4'] == 'Mild' ? true : false}>Mild</option>
                                                                <option value="Moderate" selected={treatmentData['edema_thoracic_spine_t4'] == 'Moderate' ? true : false}>Moderate</option>
                                                                <option value="Severe" selected={treatmentData['edema_thoracic_spine_t4'] == 'Severe' ? true : false}>Severe</option>
                                                                <option value="Very Severe" selected={treatmentData['edema_thoracic_spine_t4'] == 'Very Severe' ? true : false}>Very Severe</option>
                                                            </Input>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="padding-0">
                                                        <div
                                                            className={`center-align border-1 custom-radio font-size-14 `}>
                                                            <p>T5</p>
                                                            <Input
                                                                style={{ fontSize: '12px' }}
                                                                className={treatmentData["edema_thoracic_spine_t5"] != 'No' ? "custom-select-treatment custom-select-treatment-selected " : "custom-select-treatment"}
                                                                type="select" name="edema_thoracic_spine_t5" id="edema_thoracic_spine_t5" value={treatmentData['edema_thoracic_spine_t5']} onChange={(e) => { handleChangeValue(e) }}>
                                                                <option value="">Select</option>
                                                                <option value="Nil" selected={treatmentData['edema_thoracic_spine_t5'] == 'Nil' ? true : false}>Nil</option>
                                                                <option value="Mild" selected={treatmentData['edema_thoracic_spine_t5'] == 'Mild' ? true : false}>Mild</option>
                                                                <option value="Moderate" selected={treatmentData['edema_thoracic_spine_t5'] == 'Moderate' ? true : false}>Moderate</option>
                                                                <option value="Severe" selected={treatmentData['edema_thoracic_spine_t5'] == 'Severe' ? true : false}>Severe</option>
                                                                <option value="Very Severe" selected={treatmentData['edema_thoracic_spine_t5'] == 'Very Severe' ? true : false}>Very Severe</option>
                                                            </Input>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="padding-0">
                                                        <div
                                                            className={`center-align border-1 custom-radio font-size-14 `}>
                                                            <p>T6</p>
                                                            <Input
                                                                style={{ fontSize: '12px' }}
                                                                className={treatmentData["edema_thoracic_spine_t6"] != 'No' ? "custom-select-treatment custom-select-treatment-selected " : "custom-select-treatment"}
                                                                type="select" name="edema_thoracic_spine_t6" id="edema_thoracic_spine_t6" value={treatmentData['edema_thoracic_spine_t6']} onChange={(e) => { handleChangeValue(e) }}>
                                                                <option value="">Select</option>
                                                                <option value="Nil" selected={treatmentData['edema_thoracic_spine_t6'] == 'Nil' ? true : false}>Nil</option>
                                                                <option value="Mild" selected={treatmentData['edema_thoracic_spine_t6'] == 'Mild' ? true : false}>Mild</option>
                                                                <option value="Moderate" selected={treatmentData['edema_thoracic_spine_t6'] == 'Moderate' ? true : false}>Moderate</option>
                                                                <option value="Severe" selected={treatmentData['edema_thoracic_spine_t6'] == 'Severe' ? true : false}>Severe</option>
                                                                <option value="Very Severe" selected={treatmentData['edema_thoracic_spine_t6'] == 'Very Severe' ? true : false}>Very Severe</option>
                                                            </Input>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md="12" className="padding-0">
                                                <Row className="margin-0">
                                                    <Col md="2" className="padding-0">
                                                        <div
                                                            className={`center-align border-1 custom-radio font-size-14 `}>
                                                            <p>T7</p>
                                                            <Input
                                                                style={{ fontSize: '12px' }}
                                                                className={treatmentData["edema_thoracic_spine_t7"] != 'No' ? "custom-select-treatment custom-select-treatment-selected " : "custom-select-treatment"}
                                                                type="select" name="edema_thoracic_spine_t7" id="edema_thoracic_spine_t7" value={treatmentData['edema_thoracic_spine_t7']} onChange={(e) => { handleChangeValue(e) }}>
                                                                <option value="">Select</option>
                                                                <option value="Nil" selected={treatmentData['edema_thoracic_spine_t7'] == 'Nil' ? true : false}>Nil</option>
                                                                <option value="Mild" selected={treatmentData['edema_thoracic_spine_t7'] == 'Mild' ? true : false}>Mild</option>
                                                                <option value="Moderate" selected={treatmentData['edema_thoracic_spine_t7'] == 'Moderate' ? true : false}>Moderate</option>
                                                                <option value="Severe" selected={treatmentData['edema_thoracic_spine_t7'] == 'Severe' ? true : false}>Severe</option>
                                                                <option value="Very Severe" selected={treatmentData['edema_thoracic_spine_t7'] == 'Very Severe' ? true : false}>Very Severe</option>
                                                            </Input>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="padding-0">
                                                        <div
                                                            className={`center-align border-1 custom-radio font-size-14 `}>
                                                            <p>T8</p>
                                                            <Input
                                                                style={{ fontSize: '12px' }}
                                                                className={treatmentData["edema_thoracic_spine_t8"] != 'No' ? "custom-select-treatment custom-select-treatment-selected " : "custom-select-treatment"}
                                                                type="select" name="edema_thoracic_spine_t8" id="edema_thoracic_spine_t8" value={treatmentData['edema_thoracic_spine_t8']} onChange={(e) => { handleChangeValue(e) }}>
                                                                <option value="">Select</option>
                                                                <option value="Nil" selected={treatmentData['edema_thoracic_spine_t8'] == 'Nil' ? true : false}>Nil</option>
                                                                <option value="Mild" selected={treatmentData['edema_thoracic_spine_t8'] == 'Mild' ? true : false}>Mild</option>
                                                                <option value="Moderate" selected={treatmentData['edema_thoracic_spine_t8'] == 'Moderate' ? true : false}>Moderate</option>
                                                                <option value="Severe" selected={treatmentData['edema_thoracic_spine_t8'] == 'Severe' ? true : false}>Severe</option>
                                                                <option value="Very Severe" selected={treatmentData['edema_thoracic_spine_t8'] == 'Very Severe' ? true : false}>Very Severe</option>
                                                            </Input>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="padding-0">
                                                        <div
                                                            className={`center-align border-1 custom-radio font-size-14`}>
                                                            <p>T9</p>
                                                            <Input
                                                                style={{ fontSize: '12px' }}
                                                                className={treatmentData["edema_thoracic_spine_t9"] != 'No' ? "custom-select-treatment custom-select-treatment-selected " : "custom-select-treatment"}
                                                                type="select" name="edema_thoracic_spine_t9" id="edema_thoracic_spine_t9" value={treatmentData['edema_thoracic_spine_t9']} onChange={(e) => { handleChangeValue(e) }}>
                                                                <option value="">Select</option>
                                                                <option value="Nil" selected={treatmentData['edema_thoracic_spine_t9'] == 'Nil' ? true : false}>Nil</option>
                                                                <option value="Mild" selected={treatmentData['edema_thoracic_spine_t9'] == 'Mild' ? true : false}>Mild</option>
                                                                <option value="Moderate" selected={treatmentData['edema_thoracic_spine_t9'] == 'Moderate' ? true : false}>Moderate</option>
                                                                <option value="Severe" selected={treatmentData['edema_thoracic_spine_t9'] == 'Severe' ? true : false}>Severe</option>
                                                                <option value="Very Severe" selected={treatmentData['edema_thoracic_spine_t9'] == 'Very Severe' ? true : false}>Very Severe</option>
                                                            </Input>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="padding-0">
                                                        <div
                                                            className={`center-align border-1 custom-radio font-size-14 `}>
                                                            <p>T10</p>
                                                            <Input
                                                                style={{ fontSize: '12px' }}
                                                                className={treatmentData["edema_thoracic_spine_t10"] != 'No' ? "custom-select-treatment custom-select-treatment-selected " : "custom-select-treatment"}
                                                                type="select" name="edema_thoracic_spine_t10" id="edema_thoracic_spine_t10" value={treatmentData['edema_thoracic_spine_t10']} onChange={(e) => { handleChangeValue(e) }}>
                                                                <option value="">Select</option>
                                                                <option value="Nil" selected={treatmentData['edema_thoracic_spine_t10'] == 'Nil' ? true : false}>Nil</option>
                                                                <option value="Mild" selected={treatmentData['edema_thoracic_spine_t10'] == 'Mild' ? true : false}>Mild</option>
                                                                <option value="Moderate" selected={treatmentData['edema_thoracic_spine_t10'] == 'Moderate' ? true : false}>Moderate</option>
                                                                <option value="Severe" selected={treatmentData['edema_thoracic_spine_t10'] == 'Severe' ? true : false}>Severe</option>
                                                                <option value="Very Severe" selected={treatmentData['edema_thoracic_spine_t10'] == 'Very Severe' ? true : false}>Very Severe</option>
                                                            </Input>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="padding-0">
                                                        <div
                                                            className={`center-align border-1 custom-radio font-size-14 `}>
                                                            <p>T11</p>
                                                            <Input
                                                                style={{ fontSize: '12px' }}
                                                                className={treatmentData["edema_thoracic_spine_t11"] != 'No' ? "custom-select-treatment custom-select-treatment-selected " : "custom-select-treatment"}
                                                                type="select" name="edema_thoracic_spine_t11" id="edema_thoracic_spine_t11" value={treatmentData['edema_thoracic_spine_t11']} onChange={(e) => { handleChangeValue(e) }}>
                                                                <option value="">Select</option>
                                                                <option value="Nil" selected={treatmentData['edema_thoracic_spine_t11'] == 'Nil' ? true : false}>Nil</option>
                                                                <option value="Mild" selected={treatmentData['edema_thoracic_spine_t11'] == 'Mild' ? true : false}>Mild</option>
                                                                <option value="Moderate" selected={treatmentData['edema_thoracic_spine_t11'] == 'Moderate' ? true : false}>Moderate</option>
                                                                <option value="Severe" selected={treatmentData['edema_thoracic_spine_t11'] == 'Severe' ? true : false}>Severe</option>
                                                                <option value="Very Severe" selected={treatmentData['edema_thoracic_spine_t11'] == 'Very Severe' ? true : false}>Very Severe</option>
                                                            </Input>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="padding-0">
                                                        <div
                                                            className={`center-align border-1 custom-radio font-size-14 `}>
                                                            <p>T12</p>
                                                            <Input
                                                                style={{ fontSize: '12px' }}
                                                                className={treatmentData["edema_thoracic_spine_t12"] != 'No' ? "custom-select-treatment custom-select-treatment-selected " : "custom-select-treatment"}
                                                                type="select" name="edema_thoracic_spine_t12" id="edema_thoracic_spine_t12" value={treatmentData['edema_thoracic_spine_t12']} onChange={(e) => { handleChangeValue(e) }}>
                                                                <option value="">Select</option>
                                                                <option value="Nil" selected={treatmentData['edema_thoracic_spine_t12'] == 'Nil' ? true : false}>Nil</option>
                                                                <option value="Mild" selected={treatmentData['edema_thoracic_spine_t12'] == 'Mild' ? true : false}>Mild</option>
                                                                <option value="Moderate" selected={treatmentData['edema_thoracic_spine_t12'] == 'Moderate' ? true : false}>Moderate</option>
                                                                <option value="Severe" selected={treatmentData['edema_thoracic_spine_t12'] == 'Severe' ? true : false}>Severe</option>
                                                                <option value="Very Severe" selected={treatmentData['edema_thoracic_spine_t12'] == 'Very Severe' ? true : false}>Very Severe</option>
                                                            </Input>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                                <Col md="6" >
                                    <FormGroup>
                                        <Label for="f_name">Edema Lumbar Sacral Spine<span style={{ color: 'red' }}>*</span></Label>
                                        <Row className="margin-0">
                                            <Col md="12" className="padding-0">
                                                <Row className="margin-0">
                                                    <Col md="2" className="padding-0">
                                                        <div className={`center-align border-1 custom-radio lumber-sacral`}>
                                                            <p style={{ fontSize: '16px' }}>L1</p>
                                                            <Input
                                                                className={treatmentData["l_sacral_spine_l1"] ? "custom-select-treatment custom-select-treatment-selected " : "custom-select-treatment"}
                                                                type="select" name="l_sacral_spine_l1" id="l_sacral_spine_l1" value={treatmentData['l_sacral_spine_l1']} onChange={(e) => { handleChangeValue(e) }}>
                                                                <option value="">Select</option>
                                                                <option value="Nil" selected={treatmentData['l_sacral_spine_l1'] == 'Nil' ? true : false}>Nil</option>
                                                                <option value="Mild" selected={treatmentData['l_sacral_spine_l1'] == 'Mild' ? true : false}>Mild</option>
                                                                <option value="Moderate" selected={treatmentData['l_sacral_spine_l1'] == 'Moderate' ? true : false}>Moderate</option>
                                                                <option value="Severe" selected={treatmentData['l_sacral_spine_l1'] == 'Severe' ? true : false}>Severe</option>
                                                                <option value="Very Severe" selected={treatmentData['l_sacral_spine_l1'] == 'Very Severe' ? true : false}>Very Severe</option>
                                                            </Input>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="padding-0">
                                                        <div className={`center-align border-1 custom-radio lumber-sacral`}>
                                                            <p style={{ fontSize: '16px' }}>L2</p>
                                                            <Input
                                                                className={treatmentData["l_sacral_spine_l2"] ? "custom-select-treatment custom-select-treatment-selected " : "custom-select-treatment"}
                                                                type="select" name="l_sacral_spine_l2" id="l_sacral_spine_l2" value={treatmentData['l_sacral_spine_l2']} onChange={(e) => { handleChangeValue(e) }}>
                                                                <option value="">Select</option>
                                                                <option value="Nil" selected={treatmentData['l_sacral_spine_l2'] == 'Nil' ? true : false}>Nil</option>
                                                                <option value="Mild" selected={treatmentData['l_sacral_spine_l2'] == 'Mild' ? true : false}>Mild</option>
                                                                <option value="Moderate" selected={treatmentData['l_sacral_spine_l2'] == 'Moderate' ? true : false}>Moderate</option>
                                                                <option value="Severe" selected={treatmentData['l_sacral_spine_l2'] == 'Severe' ? true : false}>Severe</option>
                                                                <option value="Very Severe" selected={treatmentData['l_sacral_spine_l2'] == 'Very Severe' ? true : false}>Very Severe</option>
                                                            </Input>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="padding-0">
                                                        <div className={`center-align border-1 custom-radio lumber-sacral`}>
                                                            <p style={{ fontSize: '16px' }}>L3</p>
                                                            <Input
                                                                className={treatmentData["l_sacral_spine_l3"] ? "custom-select-treatment custom-select-treatment-selected " : "custom-select-treatment"}
                                                                type="select" name="l_sacral_spine_l3" id="l_sacral_spine_l3" value={treatmentData['l_sacral_spine_l3']} onChange={(e) => { handleChangeValue(e) }}>
                                                                <option value="">Select</option>
                                                                <option value="Nil" selected={treatmentData['l_sacral_spine_l3'] == 'Nil' ? true : false}>Nil</option>
                                                                <option value="Mild" selected={treatmentData['l_sacral_spine_l3'] == 'Mild' ? true : false}>Mild</option>
                                                                <option value="Moderate" selected={treatmentData['l_sacral_spine_l3'] == 'Moderate' ? true : false}>Moderate</option>
                                                                <option value="Severe" selected={treatmentData['l_sacral_spine_l3'] == 'Severe' ? true : false}>Severe</option>
                                                                <option value="Very Severe" selected={treatmentData['l_sacral_spine_l3'] == 'Very Severe' ? true : false}>Very Severe</option>
                                                            </Input>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="padding-0">
                                                        <div className={`center-align border-1 custom-radio lumber-sacral`}>
                                                            <p style={{ fontSize: '16px' }}>L4</p>
                                                            <Input
                                                                className={treatmentData["l_sacral_spine_l4"] ? "custom-select-treatment custom-select-treatment-selected " : "custom-select-treatment"}
                                                                type="select" name="l_sacral_spine_l4" id="l_sacral_spine_l4" value={treatmentData['l_sacral_spine_l4']} onChange={(e) => { handleChangeValue(e) }}>
                                                                <option value="">Select</option>
                                                                <option value="Nil" selected={treatmentData['l_sacral_spine_l4'] == 'Nil' ? true : false}>Nil</option>
                                                                <option value="Mild" selected={treatmentData['l_sacral_spine_l4'] == 'Mild' ? true : false}>Mild</option>
                                                                <option value="Moderate" selected={treatmentData['l_sacral_spine_l4'] == 'Moderate' ? true : false}>Moderate</option>
                                                                <option value="Severe" selected={treatmentData['l_sacral_spine_l4'] == 'Severe' ? true : false}>Severe</option>
                                                                <option value="Very Severe" selected={treatmentData['l_sacral_spine_l4'] == 'Very Severe' ? true : false}>Very Severe</option>
                                                            </Input>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="padding-0">
                                                        <div className={`center-align border-1 custom-radio lumber-sacral`}>
                                                            <p style={{ fontSize: '16px' }}>L5</p>
                                                            <Input
                                                                className={treatmentData["l_sacral_spine_l5"] ? "custom-select-treatment custom-select-treatment-selected " : "custom-select-treatment"}
                                                                type="select" name="l_sacral_spine_l5" id="l_sacral_spine_l5" value={treatmentData['l_sacral_spine_l5']} onChange={(e) => { handleChangeValue(e) }}>
                                                                <option value="">Select</option>
                                                                <option value="Nil" selected={treatmentData['l_sacral_spine_l5'] == 'Nil' ? true : false}>Nil</option>
                                                                <option value="Mild" selected={treatmentData['l_sacral_spine_l5'] == 'Mild' ? true : false}>Mild</option>
                                                                <option value="Moderate" selected={treatmentData['l_sacral_spine_l5'] == 'Moderate' ? true : false}>Moderate</option>
                                                                <option value="Severe" selected={treatmentData['l_sacral_spine_l5'] == 'Severe' ? true : false}>Severe</option>
                                                                <option value="Very Severe" selected={treatmentData['l_sacral_spine_l5'] == 'Very Severe' ? true : false}>Very Severe</option>
                                                            </Input>
                                                        </div>
                                                    </Col>


                                                </Row>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                                <Col md="6" >
                                    <FormGroup>
                                        <Label for="f_name">Sacro-Illiac Joint<span style={{ color: 'red' }}>*</span></Label>
                                        <Row className="margin-0">
                                            <Col md="6" className="padding-0">
                                                <div className={`center-align border-1 custom-radio`}>Right</div>
                                                <Row className="margin-0">
                                                    <Col md="12" className="padding-0">
                                                        <Input
                                                            className={treatmentData["right_si"] ? "custom-select-treatment custom-select-treatment-selected " : "custom-select-treatment"}
                                                            type="select" name="right_si" id="right_si" value={treatmentData['right_si']} onChange={(e) => { handleChangeValue(e) }}>
                                                            <option value="">Select</option>
                                                            <option value="Nil" selected={treatmentData['right_si'] == 'Nil' ? true : false}>Nil</option>
                                                            <option value="Mild" selected={treatmentData['right_si'] == 'Mild' ? true : false}>Mild</option>
                                                            <option value="Moderate" selected={treatmentData['right_si'] == 'Moderate' ? true : false}>Moderate</option>
                                                            <option value="Severe" selected={treatmentData['right_si'] == 'Severe' ? true : false}>Severe</option>
                                                            <option value="Very Severe" selected={treatmentData['right_si'] == 'Very Severe' ? true : false}>Very Severe</option>
                                                        </Input>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md="6" className="padding-0">
                                                <div className={`center-align border-1 custom-radio`}>Left</div>
                                                <Row className="margin-0">
                                                    <Col md="12" className="padding-0">
                                                        <Input
                                                            className={treatmentData["left_si"] ? "custom-select-treatment custom-select-treatment-selected " : "custom-select-treatment"}
                                                            type="select" name="left_si" id="left_si" value={treatmentData['left_si']} onChange={(e) => { handleChangeValue(e) }}>
                                                            <option value="">Select</option>
                                                            <option value="Nil" selected={treatmentData['left_si'] == 'Nil' ? true : false}>Nil</option>
                                                            <option value="Mild" selected={treatmentData['left_si'] == 'Mild' ? true : false}>Mild</option>
                                                            <option value="Moderate" selected={treatmentData['left_si'] == 'Moderate' ? true : false}>Moderate</option>
                                                            <option value="Severe" selected={treatmentData['left_si'] == 'Severe' ? true : false}>Severe</option>
                                                            <option value="Very Severe" selected={treatmentData['left_si'] == 'Very Severe' ? true : false}>Very Severe</option>
                                                        </Input>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                                <Col md="12" >
                                    <FormGroup>
                                        <Label for="f_name">Remarks</Label>
                                        <Input type="textarea" name="remark" id="remark" value={treatmentData["remark"]} onChange={(e) => { handleChangeValue(e) }} placeholder="Enter Remark" />
                                        {(treatmentData["remark"] === null || treatmentData["remark"] == "") && showError && (
                                            <span className="field-error">Enter Remarks to Continue</span>
                                        )}
                                    </FormGroup>
                                </Col>
                                <FormGroup>
                                    <Label for="cus_created_at">Custom Created At</Label>
                                    <Input type="date" name="cus_created_at" id="cus_created_at" defaultValue={customCreatedAt} onChange={(e) => setCustomCreatedAt(e.target.value)} />
                                </FormGroup>
                            </Row>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <button className="btn btn-primary" onClick={(e) => handleSubmit(e)}>Submit</button>
                            </div>
                        </CardBody>

                    </Card >
                </Col >
            </Row >
        </div >
    );
};

export default CenterAdd;
